.App {
  text-align: center;
}

.App-logo {
  width: 256px;
  height: 256px;
  float: left;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  width: 100vw;
  /* min-height: 100vh; * /
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-right: 1em;
  padding-bottom: -0.5em;
} */

/* .App-header h1,h2,h3,h4,h5,h6 {
  float: left;
} */

/* .App-header .header-nav {
  width: 100%;
  float: left;
  text-align: left;
} */

/* .App-header .sessions-info {
  width: 100%;
  float: right;
  text-align: right;
  font-size: smaller;
} */

/* .App-sidebar {
  width: 20%;
  min-width: fit-content;
  max-width: 10em;
  min-height: 100vh;
  float: left;
  background-color: black;
  color: white;
  padding-right: 2em;
  margin-right: 2em;
  box-shadow: black 0.25em 0 0.25em;
}*/

/* .App-sidebar .sidebar-nav {
  text-align: left;
} */

/* .App-main-content {
  min-height: 100vh;
  float: left;
  margin-top: 1em;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
